import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText,
    SttTranslateHook
} from '@stt-componentes/core';
import axios from 'axios';
import { getHeaders } from '../../request';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import { SITUACAO_TELECONSULTORIA } from '../../common/Constants';
import ConteinerRegulacao from './index';
import Chip from '@material-ui/core/Chip';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { batch, signal } from '@preact/signals-react';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        color: 'white',
        fontWeight: 'bold',
        height: 'auto',
        textShadow: '0 0 2px black',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    },
    chipSituacao: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        border: '1px solid gray',
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NUMERO: 'id',
        DATA_SOLICITACAO: 'data',
        CIDADE: 'nome_cidade',
        SOLICITANTE: 'nome_solicitante',
        ASSUNTO: 'assunto',
        CLASSIFICACAO: 'descricao_classificacao'
    }
};

const solicitacoes = signal([]);
const totalRegistros = signal(0);
const buscaEmAndamento = signal(false);
const page = signal(null)
const count = signal(null);
const order = signal(ORDENACAO.DIRECAO.ASC);
const orderBy = signal(ORDENACAO.CAMPOS.DATA_SOLICITACAO);
export const buscarRegulacao = signal(false);

const PesquisaRegulacao = ({ adicionarAba, removerAbaId }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    useSignals();

    useEffect(() => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            buscarRegulacao.value = true;
        });
    }, []);

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            buscarRegulacao.value = true;
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            buscarRegulacao.value = true;
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            buscarRegulacao.value = true;
        });
    };

    const switchPrazo = (tele) => {
        let now = moment().startOf('day');
        let dataMomentFormatada = moment(tele.data);
        let diff = moment.duration(now.diff(dataMomentFormatada)).asDays();
        if (diff > 3) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#D88080' }}
                    label={strings.prazoVencido}
                />
            );
        } else if (diff > 2) {
            return (
                <Chip
                    className={classes.chip}
                    style={{ backgroundColor: '#D1D64C' }}
                    label={strings.prazoVencendo}
                />
            );
        }
        return (
            <Chip
                className={classes.chip}
                style={{ backgroundColor: '#8AC58B' }}
                label={strings.prazoNormal}
            />
        );
    }

    const buscarTeleconsultorias = () => {
        buscaEmAndamento.value = true;

        let params = { regular: true };

        if (orderBy.value && order.value) {
            params.sort = orderBy.value;
            params.direction = order.value;
        }

        const offset = (page.value * count.value);
        params.start = offset;
        params.count = count.value;

        axios.get(`${global.gConfig.url_base_teleconsultorias}/solicitacao/aceleradores`, { params, headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;
                batch(() => {
                    totalRegistros.value = parseInt(data.totalRegistros);
                    solicitacoes.value = data.itens;
                });
            })
            .catch(err => {
                console.log(err)
                batch(() => {
                    totalRegistros.value = 0;
                    solicitacoes.value = [];
                });
            })
            .finally(() => {
                batch(() => {
                    buscaEmAndamento.value = false;
                    buscarRegulacao.value = false;
                });
            });
    };

    useSignalEffect(() => {
        if (buscarRegulacao.value) {
            buscarTeleconsultorias();
        }
    });

    return (
        <>
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    <SttTableHead>
                        <SttTableRow>
                            <SttHidden xsDown>
                                <SttTableCell width="5%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NUMERO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO)}>
                                        {strings.numero}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}>
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="14%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}>
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="14%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}>
                                        {strings.solicitante}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="18%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CLASSIFICACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CLASSIFICACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CLASSIFICACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CLASSIFICACAO)}>
                                        {strings.classificacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="17%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}>
                                        {strings.assunto}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%">
                                    {strings.situacao}
                                </SttTableCell>
                                <SttTableCell width="7%" align="center">
                                    {strings.prazoRegulacao}
                                </SttTableCell>
                                <SttTableCell width="5%" align="center">
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.teleconsultorias}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={9}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={9} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.id}</SttTableCell>
                                                    <SttTableCell>{moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade} / {row.sigla_estado}</SttTableCell>
                                                    <SttTableCell>{row.nome_solicitante}</SttTableCell>
                                                    <SttTableCell>{row.descricao_classificacao}</SttTableCell>
                                                    <SttTableCell>{row.assunto}</SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chipSituacao}
                                                            style={{ backgroundColor: SITUACAO_TELECONSULTORIA.GRID[row.situacao].cor }}
                                                            label={SITUACAO_TELECONSULTORIA.GRID[row.situacao].descricao}
                                                        />
                                                    </SttTableCell>
                                                    <SttTableCell align="center">
                                                        {switchPrazo(row)}
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.numero}:</b> {row.id}</SttText>
                                                        <SttText size="small"><b>{strings.dataSolicitacao}:</b> {moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.cidadeUf}:</b> {row.nome_cidade} / {row.sigla_estado}</SttText>
                                                        <SttText size="small"><b>{strings.solicitante}:</b> {row.nome_solicitante}</SttText>
                                                        <SttText size="small"><b>{strings.classificacao}:</b> {row.descricao_classificacao}</SttText>
                                                        <SttText size="small"><b>{strings.assunto}:</b> {row.assunto}</SttText>
                                                        <SttText size="small"><b>{strings.prazoRegulacao}:</b> {switchPrazo(row)}</SttText>
                                                        <SttText size="small"><b>{strings.situacao}:</b>
                                                            <Chip
                                                                className={classes.chipSituacao}
                                                                style={{ backgroundColor: SITUACAO_TELECONSULTORIA.GRID[row.situacao].cor }}
                                                                label={SITUACAO_TELECONSULTORIA.GRID[row.situacao].descricao}
                                                            />
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center">
                                                    <SttSeeButton
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            adicionarAba({
                                                                titulo: `${strings.regulacao} - ${row.id}`,
                                                                conteudo: ConteinerRegulacao,
                                                                id: parseInt(row.id),
                                                                adicionarAba,
                                                                removerAbaId,
                                                                idAba: `${row.id}`,
                                                            });
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.value.length > 0 &&
                <SttTablePagination
                    rowsPerPageOptions={[10, 20, 40]}
                    component="div"
                    count={totalRegistros.value}
                    rowsPerPage={count.value}
                    page={page.value}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={strings.linhasPorPagina}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                />
            }
        </>
    )
}

export default PesquisaRegulacao;
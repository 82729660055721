import React, { memo, useEffect } from 'react';
import TelaInicio from '../../componentes/inicio';
import { SttTabs } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import axios from 'axios';
import { getHeaders } from '../../request';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const Inicio = () => {
    const classes = useStyles();
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_teleconsultorias}/teleconsultor/regiao`, { headers: getHeaders() })
            .then((response) => {
                const { data } = response.data;

                if (data.length === 0) {
                    return;
                    // TODO: TRATAR TELECONSULTOR SEM VÍNCULO COM REDE
                }

                abas.value = data.map(rede => ({
                    titulo: rede.descricao,
                    idRede: rede.id_cadastro_regiao_autorregulacao,
                    permanente: true,
                    conteudo: TelaInicio,
                    key: `${rede.id_cadastro_regiao_autorregulacao}_${rede.descricao}`
                }))
            });
    }, []);

    return (
        <SttTabs abas={abas.value}
            className={classes.wrapper}
            handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
            abaAtiva={abaAtiva.value}
            permanente={true}
            preload={false}
        />
    );
};

export default memo(Inicio);
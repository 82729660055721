import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText,
    SttButton,
    SttCheckbox,
    SttTranslateHook
} from '@stt-componentes/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { temPermissaoRBAC } from '../../security/rbac';
import TableContainer from '@material-ui/core/TableContainer';
import {
    SITUACAO_TELECONSULTORIA,
    TIPO_TELECONSULTORIA,
    PERMISSOES
} from '../../common/Constants';
import Form from './form';
import Detalhes from '../visualizacao/detalhes';
import Detalhes0800 from '../visualizacao/detalhes0800';
import DetalhesCondutaClinica from '../visualizacao/detalhesCondutaClinica';
import Chip from '@material-ui/core/Chip';
import RegulacaoMultipla from './regulacaoMultipla';
import { batch } from '@preact/signals-react';
import usuario from '../../signals/usuario';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { listar } from '../../signals/pesquisa';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(2)
    },
    table: {
        overflow: 'auto'
    },
    chip: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        height: 'auto',
        '& span': {
            whiteSpace: 'pre-wrap'
        }
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        NUMERO: 'id',
        DATA_SOLICITACAO: 'data',
        DATA_RESPOSTA: 'data_resposta',
        CIDADE: 'nome_cidade',
        SOLICITANTE: 'nome_solicitante',
        TELECONSULTOR: 'nome_teleconsultor',
        ASSUNTO: 'assunto',
        LIDA: 'visualizacao_solicitante',
        SITUACAO: 'situacao'
    }
};

const CampoAssunto = ({ assunto }) => {
    if (!assunto) {
        return null;
    }

    return assunto.split('\\n').map((str, i) => (
        <p key={`AA_${i}`} style={{ marginTop: '4px', marginBottom: '4px' }}>
            {str}
        </p>
    ));
}

const { GRID, SITUACAO } = SITUACAO_TELECONSULTORIA;

const SolicitacoesTeleconsultorias = ({ adicionarAba }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    const solicitacoes = useSignal([]);
    const totalRegistros = useSignal(0);
    const buscaEmAndamento = useSignal(false);
    const page = useSignal(null)
    const count = useSignal(null);
    const order = useSignal(ORDENACAO.DIRECAO.ASC);
    const orderBy = useSignal(null);
    const regularMultiplas = useSignal(false);
    const telesSelecionadas = useSignal([]);

    useSignalEffect(() => {
        if (page.value === null || count.value === null) {
            batch(() => {
                page.value = global.gConfig.pagination.start;
                count.value = global.gConfig.pagination.count;
            });
        }
    }, []);

    const resetPageOrder = () => {
        batch(() => {
            page.value = global.gConfig.pagination.start;
            count.value = global.gConfig.pagination.count;
            order.value = ORDENACAO.DIRECAO.DESC;
            orderBy.value = null;
            telesSelecionadas.value = [];
        });
    }

    const handleClickSort = (campo) => {
        const isAsc = (orderBy.value === campo) && (order.value === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        batch(() => {
            order.value = newOrder;
            orderBy.value = campo;
            listar.value = true;
            telesSelecionadas.value = [];
        });
    }

    const handleChangePage = (event, newPage) => {
        batch(() => {
            page.value = newPage
            listar.value = true;
            telesSelecionadas.value = [];
        });
    };

    const handleChangeRowsPerPage = event => {
        batch(() => {
            count.value = event.target.value;
            page.value = global.gConfig.pagination.start;
            listar.value = true;
            telesSelecionadas.value = [];
        });
    };

    const callbackBusca = (dados) => {
        let now = moment().startOf('day');
        dados.itens.forEach(t => {
            let diff = 0;
            if (t.situacao === SITUACAO.EM_ABERTO || t.situacao === SITUACAO.ENCAMINHADA) {
                let dataMomentFormatada = moment(t.data);
                diff = moment.duration(now.diff(dataMomentFormatada)).asHours();
            } else if (t.situacao === SITUACAO.AGUARDANDO_TREPLICA || t.situacao === SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA) {
                let dataMomentFormatada = moment(t.data_resposta);
                diff = moment.duration(now.diff(dataMomentFormatada)).asHours();
            }
            if (diff > t.prazo_vencimento) {
                t.prazoVencido = true;
            }
        });

        batch(() => {
            totalRegistros.value = parseInt(dados.totalRegistros);
            solicitacoes.value = dados.itens;
            listar.value = false;
            telesSelecionadas.value = [];
        });
    }

    const selecionarTodasTeleconsultorias = (selecionar) => {
        if (!solicitacoes.value.length) {
            return;
        }

        if (selecionar) {
            telesSelecionadas.value = solicitacoes.value.filter(s => s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.EM_ABERTO || s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA).map(s => s.id);
        } else {
            telesSelecionadas.value = [];
        }
    }

    return (
        <>
            <Form
                callbackBusca={callbackBusca}
                buscaEmAndamento={buscaEmAndamento}
                page={page}
                count={count}
                orderBy={orderBy}
                order={order}
                resetPageOrder={resetPageOrder}
            />
            <TableContainer className={classes.tableWrapper} component={Paper}>
                <SttTable>
                    {
                        telesSelecionadas.value.length > 0 && temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                        <caption>
                            <div className={classes.contentWrapper}>
                                {
                                    telesSelecionadas.value.length === 1 && `${telesSelecionadas.value.length} ${strings.teleSelecionada}`
                                }
                                {
                                    telesSelecionadas.value.length > 1 && `${telesSelecionadas.value.length} ${strings.telesSelecionadas}`
                                }
                                <SttButton
                                    type="button"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => regularMultiplas.value = true}
                                >
                                    {strings.regularTeleconsultoria}
                                </SttButton>
                            </div>
                        </caption>
                    }
                    <SttTableHead>
                        <SttTableRow>
                            {
                                temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                <SttTableCell width="2%">
                                    <SttCheckbox
                                        color="primary"
                                        checked={
                                            solicitacoes.value.length > 0 &&
                                            telesSelecionadas.value.length > 0 &&
                                            (telesSelecionadas.value.filter(e =>
                                                (solicitacoes.value.filter(s => s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.EM_ABERTO || s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA)
                                                    .map(e => e.id)).includes(e))
                                            ).length === solicitacoes.value.filter(s => s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.EM_ABERTO || s.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA).length
                                        }
                                        onChange={(evt, value) => selecionarTodasTeleconsultorias(value)}
                                    />
                                </SttTableCell>
                            }
                            <SttHidden xsDown>
                                <SttTableCell width="4%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.NUMERO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.NUMERO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.NUMERO)}>
                                        {strings.numeroTeleconsultoria}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="7%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_SOLICITACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_SOLICITACAO)}>
                                        {strings.dataSolicitacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="7%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.DATA_RESPOSTA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA_RESPOSTA)}>
                                        {strings.dataResposta}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.CIDADE}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.CIDADE ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE)}>
                                        {strings.cidadeUf}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                {
                                    temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                    <SttTableCell width="8%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : false}>
                                        <SttTableSortLabel
                                            active={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE}
                                            direction={orderBy.value === ORDENACAO.CAMPOS.SOLICITANTE ? order.value : ORDENACAO.DIRECAO.ASC}
                                            onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}>
                                            {strings.solicitante}
                                        </SttTableSortLabel>
                                    </SttTableCell>
                                }
                                <SttTableCell width="14%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.TELECONSULTOR ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.TELECONSULTOR)}>
                                        {strings.nomeTeleconsultor}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="16%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.ASSUNTO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.ASSUNTO)}>
                                        {strings.assunto}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="10%">
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.SITUACAO}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.SITUACAO ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SITUACAO)}>
                                        {strings.situacao}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="4%" sortDirection={orderBy.value === ORDENACAO.CAMPOS.LIDA ? order.value : false}>
                                    <SttTableSortLabel
                                        active={orderBy.value === ORDENACAO.CAMPOS.LIDA}
                                        direction={orderBy.value === ORDENACAO.CAMPOS.LIDA ? order.value : ORDENACAO.DIRECAO.ASC}
                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.LIDA)}>
                                        {strings.lida}
                                    </SttTableSortLabel>
                                </SttTableCell>
                                <SttTableCell width="5%" align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box', backgroundColor: 'white', }}>
                                    {strings.visualizar}
                                </SttTableCell>
                            </SttHidden>
                            <SttHidden smUp>
                                <SttTableCell width="100%" colSpan="2">{strings.teleconsultorias}</SttTableCell>
                            </SttHidden>
                        </SttTableRow>
                    </SttTableHead>
                    <SttTableBody>
                        {
                            buscaEmAndamento.value
                                ?
                                <SttTableRow key={-1}>
                                    <SttTableCell colSpan={10}
                                        align="center">
                                        <SttCircularProgress />
                                    </SttTableCell>
                                </SttTableRow>
                                :
                                (
                                    solicitacoes.value.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={10} align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        solicitacoes.value.map((row, index) => (
                                            <SttTableRow hover key={index}>
                                                {
                                                    temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                                    <SttTableCell>
                                                        <SttCheckbox
                                                            color="primary"
                                                            checked={telesSelecionadas.value.includes(row.id)}
                                                            disabled={row.situacao !== SITUACAO_TELECONSULTORIA.SITUACAO.EM_ABERTO && row.situacao !== SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA}
                                                            onChange={(evt, value) => {
                                                                if (value) {
                                                                    telesSelecionadas.value = [...telesSelecionadas.value, row.id];
                                                                } else {
                                                                    telesSelecionadas.value = telesSelecionadas.value.filter(e => e !== row.id);
                                                                }
                                                            }}
                                                        />
                                                    </SttTableCell>
                                                }
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.id}</SttTableCell>
                                                    <SttTableCell>{moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttTableCell>
                                                    <SttTableCell>{row.data_resposta ? moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss') : ''}</SttTableCell>
                                                    <SttTableCell>{row.nome_cidade ? `${row.nome_cidade} / ${row.sigla_estado}` : ''}</SttTableCell>
                                                    {
                                                        temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                                        <SttTableCell>{row.nome_solicitante}</SttTableCell>
                                                    }
                                                    <SttTableCell>{row.nome_teleconsultor}</SttTableCell>
                                                    <SttTableCell><CampoAssunto assunto={row.assunto} /></SttTableCell>
                                                    <SttTableCell>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.prazoVencido ? '#faf7a9' : GRID[row.situacao].cor, border: '1px solid gray' }}
                                                            label={GRID[row.situacao].descricao}
                                                        />
                                                    </SttTableCell>
                                                    <SttTableCell>{row.visualizacao_solicitante ? strings.sim : strings.nao}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small"><b>{strings.numero}:</b> {row.id}</SttText>
                                                        <SttText size="small"><b>{strings.dataSolicitacao}:</b> {moment(row.data).format('DD/MM/YYYY HH:mm:ss')}</SttText>
                                                        <SttText size="small"><b>{strings.dataResposta}:</b> {row.data_resposta ? moment(row.data_resposta).format('DD/MM/YYYY HH:mm:ss') : ''}</SttText>
                                                        <SttText size="small"><b>{strings.cidadeUf}:</b> {row.nome_cidade ? `${row.nome_cidade} / ${row.sigla_estado}` : ''}</SttText>
                                                        {
                                                            temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                                                            <SttText size="small"><b>{strings.solicitante}:</b> {row.nome_solicitante}</SttText>
                                                        }
                                                        <SttText size="small"><b>{strings.teleconsultor}:</b> {row.nome_teleconsultor}</SttText>
                                                        <SttText size="small"><b>{strings.assunto}:</b> </SttText><CampoAssunto assunto={row.assunto} />
                                                        <SttText size="small"><b>{strings.lida}</b> {row.visualizacao_solicitante ? strings.sim : strings.nao}</SttText>
                                                        <Chip
                                                            className={classes.chip}
                                                            style={{ backgroundColor: row.prazoVencido ? '#F7FC75' : GRID[row.situacao].cor, border: '1px solid gray' }}
                                                            label={GRID[row.situacao].descricao}
                                                        />
                                                    </SttTableCell>
                                                </SttHidden>
                                                <SttTableCell align="center" style={{ position: 'sticky', right: 0, backgroundClip: 'padding-box' }}>
                                                    <SttSeeButton
                                                        style={{ backgroundColor: 'white' }}
                                                        id={`btn-ver-solicitacao-${index}`}
                                                        onClick={() => {
                                                            if (row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.ASSINCRONA || row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.SINCRONA) {
                                                                adicionarAba({
                                                                    titulo: `${strings.solicitacao} - ${row.id}`,
                                                                    conteudo: Detalhes,
                                                                    id: row.id,
                                                                    adicionarAba,
                                                                    idAba: `${row.tipo_teleconsultoria}_${row.id}`
                                                                });
                                                                return;
                                                            }
                                                            if (row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO.CC) {
                                                                let idCC = row.id.replace(/\D+/g, '');
                                                                adicionarAba({
                                                                    titulo: `${TIPO_TELECONSULTORIA.LABEL[TIPO_TELECONSULTORIA.TIPO.CC]} - ${idCC}`,
                                                                    conteudo: DetalhesCondutaClinica,
                                                                    id: idCC,
                                                                    adicionarAba,
                                                                    idAba: row.id
                                                                });
                                                                return;
                                                            }
                                                            if (row.tipo_teleconsultoria === TIPO_TELECONSULTORIA.TIPO['0800']) {
                                                                let id0800 = row.id.replace('0800_', '');
                                                                adicionarAba({
                                                                    titulo: `${TIPO_TELECONSULTORIA.LABEL[TIPO_TELECONSULTORIA.TIPO['0800']]} - ${id0800}`,
                                                                    conteudo: Detalhes0800,
                                                                    id: id0800,
                                                                    idAba: row.id
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </SttTableCell>
                                            </SttTableRow>
                                        ))
                                )
                        }
                    </SttTableBody>
                </SttTable>
            </TableContainer>
            {
                solicitacoes.value.length > 0 &&
                <>
                    <SttTablePagination
                        rowsPerPageOptions={[10, 20, 40]}
                        component="div"
                        count={totalRegistros.value}
                        rowsPerPage={count.value}
                        page={page.value}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage={strings.linhasPorPagina}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${strings.de} ${count}`}
                    />
                    {
                        regularMultiplas.value && temPermissaoRBAC(usuario, PERMISSOES.REGULACAO) &&
                        <RegulacaoMultipla
                            telesSelecionadas={telesSelecionadas}
                            regularMultiplas={regularMultiplas}
                            callback={() => {
                                listar.value = true;
                                regularMultiplas.value = false;
                                telesSelecionadas.value = [];
                            }}
                        />
                    }
                </>
            }
        </>
    )
}

export default SolicitacoesTeleconsultorias;
import React, { useEffect, memo, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';
import { getHeaders } from '../../request';
import Moment from 'react-moment';
import moment from 'moment';
import {
    SttExpansionPanel,
    SttTextItem,
    SttText,
    SttHeading,
    SttContainer,
    SttCircularProgress,
    SttDivider,
    SttNotification,
    SttTranslateHook
} from '@stt-componentes/core';
import { Util } from '@stt-utilitarios/core'
import { TIPO_TELECONSULTORIA, SITUACAO_TELECONSULTORIA, CLASSIFICACOES } from '../../common/Constants';
import Functions from '../../common/Functions';
import { SttIMC } from '@stt-componentes/paciente'
import Resposta from './resposta';
import Avaliacao from './avaliacao';
import ExibicaoAnexo from './exibicaoAnexo';
import ExibicaoHtml from './exibicaoHtml';
import ExibicaoCid10 from './exibicaoCid10';
import ExibicaoCiap2 from './exibicaoCiap2';
import Historico from '../pesquisa/historico';
import BotoesOperacoes from './botoesOperacoes';
import ExibicaoMedicamento from './exibicaoMedicamento';
import ExibicaoInsumo from './exibicaoInsumo';
import ExibicaoNutricao from './exibicaoNutricao';
import ExibicaoProcedimentosCbhpm from './exibicaoProcedimentosCbhpm';
import ExibicaoProcedimentosSigtap from './exibicaoProcedimentosSigtap';
import RespostaComplementar from './respostaComplementar';
import { ESFERA_ORGAO_JUDICIAL, REUS_JUDICIAL, TIPO_JUIZADO_JUDICIAL, TIPO_REPRESENTACAO_JUDICIAL } from '../../common/ConstantsJudicial';
import AnexosRegiao from './anexosRegiao';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import usuario from '../../signals/usuario';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import AdendoFormulario from './adendo';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    textoQuebraLinha: {
        wordBreak: 'break-all',
        whiteSpace: 'pre-line',
    },
    link: {
        padding: '5px'
    },
    notificacao: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        cursor: 'pointer',
        fontSize: '15px',
        alignItems: 'center',
    },
    heading: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5)
    }
}));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Detalhes = ({ id = null, adicionarAba, esconderAcoes = false, esconderNotificacaoRede = false, teleconsultoriaInicial = null, removerAba = null }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const teleconsultoria = useSignal(teleconsultoriaInicial);
    const exibirAnexosRegiao = useSignal(false);

    const baixarTeleconsultoria = () => {
        const TELECONSULTORIAS_API_BASE_URL = global.gConfig.url_base_teleconsultorias;
        axios.get(`${TELECONSULTORIAS_API_BASE_URL}/solicitacao/${id}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    let { data } = response.data;

                    data.acoes = Functions.acoes(usuario, data);
                    data = Functions.formatarParaVisualizacao(data);

                    teleconsultoria.value = data;
                }
            })
            .catch(err => console.log(err));
    }

    useEffect(() => {
        if (id) {
            baixarTeleconsultoria();
        }
    }, [id]);

    return (
        <SttContainer>
            {
                !teleconsultoria.value
                    ?
                    <div className={classes.carregando}>
                        <SttCircularProgress color="primary" />
                    </div>
                    :
                    <>
                        <SttHeading variant="h1" color="primary" align="center">{strings.solicitacaoTele}</SttHeading>

                        {
                            teleconsultoria.value.situacao >= SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_AVALIACAO &&
                            <SttNotification severity="info" className={classes.notificacao}>
                                {strings.informacaoTeleRespondida}
                            </SttNotification>
                        }

                        {
                            (teleconsultoria.value.acoes.podeVisualizarMovimentacoes && teleconsultoria.value.movimentacoes?.length > 0) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.movimentacoes}
                                    children={
                                        <>
                                            {
                                                teleconsultoria.value.movimentacoes.map((mov, index) => (
                                                    <div key={index}>
                                                        <SttHeading variant="h4" color="primary" className={classes.heading}>{`${strings.movimentacao} ${teleconsultoria.value.movimentacoes.length - index}`}</SttHeading>
                                                        <SttTextItem key={`A_${index}`} title={strings.dataHora} content={moment(mov.data_hora).format('DD/MM/YYYY HH:mm')} />
                                                        <SttTextItem key={`B_${index}`} title={strings.responsavel} content={mov.nome_funcionario} />
                                                        <SttTextItem key={`C_${index}`} title={strings.movimentacao} content={mov.conteudo.replace(/<br>/g, '\n')} classContent={classes.textoQuebraLinha} />
                                                    </div>
                                                ))
                                            }
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            (!teleconsultoria.value.acoes.podeVisualizarMovimentacoes && teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.FINALIZADA_ADMINISTRATIVAMENTE) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.finalizacaoAdministrativa}
                                    children={
                                        <>
                                            <SttTextItem title={strings.dataHora} content={moment(teleconsultoria.value.movimentacoes[0].data_hora).format('DD/MM/YYYY HH:mm')} />
                                            <SttTextItem title={strings.responsavel} content={teleconsultoria.value.movimentacoes[0].nome_funcionario} />
                                            <SttTextItem title={strings.movimentacao} content={teleconsultoria.value.movimentacoes[0].conteudo.replace(/<br>/g, '\n')} classContent={classes.textoQuebraLinha} />
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }

                        <SttExpansionPanel
                            title={strings.dadosGerais}
                            children={
                                <div>
                                    <SttTextItem title={strings.tipoTeleconsultoria} content={TIPO_TELECONSULTORIA.LABEL[teleconsultoria.value.tipo]} />
                                    {
                                        teleconsultoria.value.classificacao?.descricao &&
                                        <SttTextItem title={strings.classificacao} content={teleconsultoria.value.classificacao.descricao} />
                                    }
                                    {
                                        teleconsultoria.value.especificacao?.descricao &&
                                        <SttTextItem title={strings.especificacao} content={teleconsultoria.value.especificacao.descricao} />
                                    }
                                    {
                                        teleconsultoria.value.regiao?.descricao &&
                                        <SttTextItem title={strings.redeTeleconsultoria} content={teleconsultoria.value.regiao.descricao} />
                                    }
                                    {
                                        teleconsultoria.value.regiao?.anexos?.length > 0 && !esconderNotificacaoRede &&
                                        <SttNotification severity="warning"
                                            className={classes.notificacao}
                                            icon={<ReportProblemOutlinedIcon fontSize='large' />}
                                            onClick={() => exibirAnexosRegiao.value = true}
                                        >
                                            <b>{strings.atencao}!</b>&nbsp;{strings.verificamosRedeAnexo}
                                        </SttNotification>
                                    }
                                    {
                                        teleconsultoria.value.textoAssunto &&
                                        <SttTextItem title={strings.assunto} content={teleconsultoria.value.textoAssunto} />
                                    }
                                    {
                                        teleconsultoria.value.formaOrganizacao?.codigo &&
                                        <SttTextItem title={strings.formaOrganizacao} content={teleconsultoria.value.formaOrganizacao.nome} />
                                    }
                                    {
                                        teleconsultoria.value.detalhamento &&
                                        <>
                                            {
                                                teleconsultoria.value.detalhamento.descricao &&
                                                <SttTextItem title={strings.detalhamento} content={teleconsultoria.value.detalhamento.descricao} />
                                            }
                                            {
                                                teleconsultoria.value.detalhamento.ciap2_capitulo &&
                                                <SttTextItem title={strings.detalhamentoCiapCapitulo} content={teleconsultoria.value.detalhamento.ciap2_capitulo} />
                                            }
                                            {
                                                teleconsultoria.value.detalhamento.ciap2_conceito &&
                                                <SttTextItem title={strings.detalhamentoCiapConceito} content={teleconsultoria.value.detalhamento.ciap2_conceito} />
                                            }
                                        </>
                                    }
                                    {
                                        teleconsultoria.value.descricao_tema &&
                                        <SttTextItem title={strings.area} content={teleconsultoria.value.descricao_tema} />
                                    }
                                    {
                                        teleconsultoria.value.data_solicitacao &&
                                        <SttTextItem title={strings.dataSolicitacao} content={moment(teleconsultoria.value.data_solicitacao).format('DD/MM/YYYY HH:mm')} />
                                    }
                                    {
                                        (teleconsultoria.value.autorregulada && teleconsultoria.value.acoes.podeVisualizarRegulacaoAutomatica) &&
                                        <SttHeading variant="h4" color="primary" style={{ marginTop: '4px' }}>{strings.teleReguladaAutomaticamente}</SttHeading>
                                    }
                                </div>
                            }
                        />
                        <Divider />

                        <SttExpansionPanel
                            title={strings.dadosSolicitante}
                            children={
                                <div>
                                    {
                                        <SttTextItem title={strings.nome} content={teleconsultoria.value.nome_solicitante} />
                                    }
                                    {
                                        teleconsultoria.value.equipe_saude &&
                                        <SttTextItem title={strings.equipeSaudeFamilia} content={teleconsultoria.value.equipe_saude} />
                                    }
                                    {
                                        teleconsultoria.value.cidade_solicitante &&
                                        <SttTextItem title={strings.cidadeUf} content={`${teleconsultoria.value.cidade_solicitante} / ${teleconsultoria.value.estado_solicitante}`} />
                                    }
                                    {
                                        teleconsultoria.value.cbo_familia &&
                                        <SttTextItem title={strings.cbo} content={teleconsultoria.value.cbo_familia} />
                                    }
                                    {
                                        teleconsultoria.value.funcionario_provab &&
                                        <SttText>{strings.integranteProvab}</SttText>
                                    }
                                    {
                                        teleconsultoria.value.funcionario_mais_medicos &&
                                        <SttText>{strings.integranteMaisMedicos}</SttText>
                                    }
                                    {
                                        teleconsultoria.value.funcionario_residente &&
                                        <SttText>{strings.residente}</SttText>
                                    }
                                </div>
                            }
                        />
                        <Divider />

                        {
                            (
                                (teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.JUDICIALIZACAO ||
                                    teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.ENCAMINHAMENTO ||
                                    teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.CIRURGIA ||
                                    teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.DUVIDAS_CLINICAS_GERAIS) && teleconsultoria.value.nome_paciente
                            ) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosPaciente}
                                    children={
                                        <div>
                                            {
                                                teleconsultoria.value.acoes.podeVisualizarHistorico && teleconsultoria.value.pacienteTemHistorico &&
                                                <SttNotification severity="warning"
                                                    className={classes.notificacao}
                                                    icon={<ReportProblemOutlinedIcon fontSize='large' />}
                                                    onClick={() => {
                                                        adicionarAba({
                                                            titulo: `${strings.historico} - ${teleconsultoria.value.nome_paciente}`,
                                                            conteudo: Historico,
                                                            cpf: teleconsultoria.value.cpf_paciente,
                                                            cns: teleconsultoria.value.cns_paciente,
                                                            adicionarAba,
                                                            idAba: `${teleconsultoria.value.cpf_paciente}_${teleconsultoria.value.cns_paciente}`
                                                        });
                                                    }}
                                                >
                                                    <b>{strings.atencao}!</b>&nbsp;{strings.verificamosTelePacienteRespondida}
                                                </SttNotification>
                                            }
                                            {
                                                teleconsultoria.value.nome_paciente &&
                                                <SttTextItem title={strings.nome} content={teleconsultoria.value.nome_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.sexo_paciente &&
                                                <SttTextItem title={strings.sexo} content={teleconsultoria.value.sexo_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.cpf_paciente &&
                                                <SttTextItem title={strings.cpf} content={Util.util.adicionarMascara(`000${teleconsultoria.value.cpf_paciente}`, '000.000.000-00', { reverse: true })} />
                                            }
                                            {
                                                teleconsultoria.value.cns_paciente &&
                                                <SttTextItem title={strings.cns} content={teleconsultoria.value.cns_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.data_nascimento_paciente &&
                                                <SttTextItem title={strings.dataNascimento} content={<Moment format="DD/MM/YYYY">{teleconsultoria.value.data_nascimento_paciente}</Moment>} />
                                            }
                                            {
                                                teleconsultoria.value.idade_paciente &&
                                                <SttTextItem title={strings.idade} content={teleconsultoria.value.idade_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.cidade_paciente &&
                                                <SttTextItem title={strings.cidadeUf} content={`${teleconsultoria.value.cidade_paciente} / ${teleconsultoria.value.estado_paciente}`} />
                                            }
                                            {
                                                teleconsultoria.value.raca_paciente &&
                                                <SttTextItem title={strings.racaEtnia} content={teleconsultoria.value.raca_paciente} />
                                            }
                                            {
                                                teleconsultoria.value.peso &&
                                                <SttTextItem title={strings.peso} content={teleconsultoria.value.peso} />
                                            }
                                            {
                                                teleconsultoria.value.altura &&
                                                <SttTextItem title={strings.altura} content={teleconsultoria.value.altura} />
                                            }
                                            {
                                                teleconsultoria.value.peso && teleconsultoria.value.altura && teleconsultoria.value.data_nascimento_paciente &&
                                                <SttIMC values={{
                                                    peso: teleconsultoria.value.peso,
                                                    altura: teleconsultoria.value.altura,
                                                    dataNascimento: teleconsultoria.value.data_nascimento_paciente,
                                                }} strings={strings} />
                                            }
                                            <br />
                                            {
                                                teleconsultoria.value.intencao_encaminhamento != null &&
                                                <SttTextItem title={strings.intencaoEncaminhamento} content={teleconsultoria.value.intencao_encaminhamento ? strings.sim : strings.nao} />
                                            }
                                            <ExibicaoHtml titulo={strings.historiaAtualDuvida} html={teleconsultoria.value.historia_atual_queixa_principal} />
                                            <ExibicaoHtml titulo={strings.duvida} html={teleconsultoria.value.duvida_corrigida} />
                                            <ExibicaoCid10 titulo={strings.cid10} cid={teleconsultoria.value.cid10} />
                                            <ExibicaoCiap2 titulo={strings.ciap2} ciap2={teleconsultoria.value.ciap2} />
                                            <ExibicaoHtml titulo={strings.habitos} html={teleconsultoria.value.habitos} />
                                            <ExibicaoHtml titulo={strings.historiaPregressa} html={teleconsultoria.value.historia_pregressa} />
                                            <ExibicaoHtml titulo={strings.comorbidades} html={teleconsultoria.value.comorbidades} />
                                            <ExibicaoHtml titulo={strings.tratamentosAndamento} html={teleconsultoria.value.medicamentos_plantas_em_uso} />
                                            <ExibicaoHtml titulo={strings.tratamentosAnteriores} html={teleconsultoria.value.tratamentos_anteriores} />
                                            <ExibicaoHtml titulo={strings.examesImagem} html={teleconsultoria.value.resultados_exames_imagem} />
                                            <ExibicaoHtml titulo={strings.examesLaboratoriais} html={teleconsultoria.value.resultados_exames_laboratoriais} />
                                            <ExibicaoHtml titulo={strings.complementacoes} html={teleconsultoria.value.complementacoes} />
                                            <ExibicaoHtml titulo={strings.diagnostico} html={teleconsultoria.value.judicializacao?.diagnostico} />
                                        </div>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            (teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.JUDICIALIZACAO) &&
                            teleconsultoria.value.judicializacao &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosProcesso}
                                    children={
                                        <div>
                                            {
                                                teleconsultoria.value.judicializacao.numero_processo &&
                                                <SttTextItem title={strings.numeroProcesso} content={Util.util.adicionarMascara(`000000000000000${teleconsultoria.value.judicializacao.numero_processo}`, '0000000-00.0000.0.00.0000', { reverse: true })} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.vara_serventia &&
                                                <SttTextItem title={strings.varaServentia} content={teleconsultoria.value.judicializacao.vara_serventia} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.esfera_orgao &&
                                                <SttTextItem title={strings.esferaOrgao} content={ESFERA_ORGAO_JUDICIAL.LABEL[teleconsultoria.value.judicializacao.esfera_orgao]} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.reus &&
                                                <SttTextItem title={strings.reus} content={REUS_JUDICIAL.LABEL[teleconsultoria.value.judicializacao.reus]} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.tipo_representacao &&
                                                <SttTextItem title={strings.tipoRepresentacao} content={TIPO_REPRESENTACAO_JUDICIAL.LABEL[teleconsultoria.value.judicializacao.tipo_representacao]} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.assistencia_judiciaria_gratuita != null &&
                                                <SttTextItem title={strings.assitenciaJudiciaria} content={teleconsultoria.value.judicializacao.assistencia_judiciaria_gratuita ? strings.sim : strings.nao} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.tipo_juizado &&
                                                <SttTextItem title={strings.tipoJuizado} content={TIPO_JUIZADO_JUDICIAL.LABEL[teleconsultoria.value.judicializacao.tipo_juizado]} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.nome_advogado &&
                                                <SttTextItem title={strings.nomeAdvogado} content={teleconsultoria.value.judicializacao.nome_advogado} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.sigla_uf_oab && teleconsultoria.value.judicializacao.numero_oab &&
                                                <SttTextItem title={strings.numeroOab} content={`${teleconsultoria.value.judicializacao.sigla_uf_oab}${teleconsultoria.value.judicializacao.numero_oab}`} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.autor_representado_assistido != null &&
                                                <SttTextItem title={strings.autorRepresentado} content={teleconsultoria.value.judicializacao.autor_representado_assistido ? strings.sim : strings.nao} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.nome_representante &&
                                                <SttTextItem title={strings.nomeRepresentante} content={teleconsultoria.value.judicializacao.nome_representante} />
                                            }
                                            {
                                                teleconsultoria.value.judicializacao.descricao_parentesco &&
                                                <SttTextItem title={strings.parentesco} content={teleconsultoria.value.judicializacao.descricao_parentesco} />
                                            }
                                        </div>
                                    }
                                />
                                <Divider />
                                <SttExpansionPanel
                                    title={strings.tecnologia}
                                    children={
                                        <div>
                                            <ExibicaoMedicamento medicamentos={teleconsultoria.value.judicializacao.medicamentos} />
                                            <ExibicaoInsumo insumos={teleconsultoria.value.judicializacao.insumos} />
                                            <ExibicaoNutricao nutricoes={teleconsultoria.value.judicializacao.nutricao} />
                                            <ExibicaoProcedimentosCbhpm procedimentos={teleconsultoria.value.judicializacao.procedimentosCbhpm} />
                                            <ExibicaoProcedimentosSigtap procedimentos={teleconsultoria.value.judicializacao.procedimentosSigtap} />
                                        </div>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            (
                                teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.LITIGIO ||
                                teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.PROCESSO_TRABALHO ||
                                (teleconsultoria.value.classificacao?.identificador === CLASSIFICACOES.DUVIDAS_CLINICAS_GERAIS && !teleconsultoria.value.nome_paciente)
                            ) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosSolicitacao}
                                    children={
                                        <>
                                            <ExibicaoHtml titulo={strings.duvida} html={teleconsultoria.value.duvida_corrigida} />
                                            <ExibicaoCiap2 titulo={strings.ciap2} ciap2={teleconsultoria.value.ciap2} />
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }

                        {/* Conteúdo dos adendos de formulário de solicitação */}
                        <AdendoFormulario solicitacao={teleconsultoria.value} />
                        
                        {
                            (teleconsultoria.value.anexos?.length > 0) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.anexosSolicitacao}
                                    children={
                                        <>
                                            <ExibicaoAnexo titulo={strings.anexos} anexos={teleconsultoria.value.anexos} />
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            (
                                (teleconsultoria.value.devolucao?.length > 0) &&
                                ((teleconsultoria.value.acoes.podeVisualizarDevolucao) || (teleconsultoria.value.acoes.podeAvaliarAlterar && teleconsultoria.value.situacao_solicitacao === SITUACAO_TELECONSULTORIA.SITUACAO.DEVOLVIDA_SOLICITANTE))
                            ) &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosDevolucao}
                                    children={
                                        <>
                                            <SttHeading variant="h4" color="primary" className={classes.heading}>{strings.teleconsultoriaDevolvida}</SttHeading>
                                            {
                                                teleconsultoria.value.devolucao?.map((dev, index) =>
                                                    <div key={index}>
                                                        <SttHeading variant="h4" color="primary" className={classes.heading}>{strings.devolucao} {(teleconsultoria.value.devolucao.length - index)}</SttHeading>
                                                        <SttTextItem title={strings.responsavel} content={dev.nome_funcionario_devolucao} />
                                                        <SttTextItem title={strings.dataHora} content={moment(dev.data_hora_devolucao).format('DD/MM/YYYY HH:mm')} />
                                                        <SttTextItem title={strings.motivo} content={dev.motivo_devolucao} />
                                                        {
                                                            dev.complemento_motivo_devolucao &&
                                                            <SttTextItem title={strings.complemento} content={dev.complemento_motivo_devolucao} />
                                                        }
                                                        {
                                                            index < (teleconsultoria.value.devolucao.length - 1) &&
                                                            <Divider />
                                                        }
                                                    </div>
                                                )
                                            }
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            teleconsultoria.value.invalidacao &&
                            <>
                                <SttExpansionPanel
                                    title={strings.dadosInvalidacao}
                                    children={
                                        <>
                                            <SttTextItem title={strings.responsavel} content={teleconsultoria.value.invalidacao.nome_invalidador} />
                                            <SttTextItem title={strings.dataHora} content={moment(teleconsultoria.value.invalidacao.data).format('DD/MM/YYYY HH:mm')} />
                                            <SttTextItem title={strings.motivo} content={teleconsultoria.value.invalidacao.motivo} />
                                            {
                                                teleconsultoria.value.invalidacao.observacao &&
                                                <SttTextItem title={strings.observacao} content={teleconsultoria.value.invalidacao.observacao} />
                                            }
                                            <ExibicaoAnexo titulo={strings.anexos} anexos={teleconsultoria.value.invalidacao.anexos} />
                                        </>
                                    }
                                />
                                <Divider />
                            </>
                        }
                        {
                            (
                                teleconsultoria.value.situacao >= SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_AVALIACAO ||
                                (teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA && teleconsultoria.value.acoes.podeAlterarResposta) ||
                                ((
                                    teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.ENCAMINHADA || teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_LIBERACAO || teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.AGUARDANDO_RESPOSTA_SECUNDARIA
                                ) && teleconsultoria.value.acoes.podeVisualizarRespostaTemporaria)
                            ) &&
                            <>
                                <Resposta solicitacao={teleconsultoria.value} />
                                {
                                    teleconsultoria.value.segundaOpiniao &&
                                    <RespostaComplementar segundaOpiniao={teleconsultoria.value.segundaOpiniao} />
                                }
                            </>
                        }
                        {
                            teleconsultoria.value.situacao === SITUACAO_TELECONSULTORIA.SITUACAO.CONCLUIDA &&
                            <Avaliacao avaliacao={teleconsultoria.value.avaliacao} />
                        }
                        {
                            !esconderAcoes &&
                            <BotoesOperacoes teleconsultoria={teleconsultoria} baixarTeleconsultoria={baixarTeleconsultoria} removerAba={removerAba} />
                        }
                        {
                            exibirAnexosRegiao.value &&
                            <AnexosRegiao anexos={teleconsultoria.value.regiao?.anexos}
                                exibirAnexosRegiao={exibirAnexosRegiao}
                            />

                        }
                    </>
            }
        </SttContainer>
    )

}

export default Detalhes;
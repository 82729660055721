import React, { useContext, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import {
    SttHeader,
    SttMainNavigationBar,
    SttSplashScreen,
    MateriaisSuporte,
    SttTranslateHook,
    SttNotificationScreen,
    SttHelpdeskShortcut,
} from '@stt-componentes/core';
import { temPermissaoRBAC } from '../security/rbac';
import { PERMISSOES } from '../common/Constants';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import { getHeaders } from '../request';
import { signal, batch } from '@preact/signals-react';
import usuario from '../signals/usuario';
import alerta from '../signals/alerta';
import { listar } from '../signals/pesquisa';
import authModo from '../signals/authModo';

const submenus = signal([]);
const tabAtual = signal(0);
const openMS = signal(false);
const openSS = signal(false);

const Menu = () => {
    const history = useHistory();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    useSignals();

    const exibirNotificacao = useSignal(false);

    useSignalEffect(() => {
        if (usuario.value) {
            if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
                history.push("/regulacao");
            } else if (temPermissaoRBAC(usuario, PERMISSOES.RESPOSTA)) {
                history.push("/inicio");
            } else if (temPermissaoRBAC(usuario, PERMISSOES.SOLICITACAO)) {
                history.push("/solicitacao");
            }
        }
    });

    useSignalEffect(() => {
        let menuOptions = [];
        if (usuario.value) {
            //Tratamento de permissão do usuário
            if (!temPermissaoRBAC(usuario, PERMISSOES.TELECONSULTORIAS)) {
                history.push(`/nao-autorizado`);
                return;
            }
            exibirNotificacao.value = true;
            if (temPermissaoRBAC(usuario, PERMISSOES.SOLICITACAO)) {
                menuOptions = [
                    ...menuOptions,
                    {
                        id: '/solicitacao',
                        text: strings.novaSolicitacao,
                        onClick: () => {
                            if (history.location.pathname !== '/solicitacao') {
                                history.push("/solicitacao");
                            }
                        }
                    },
                    {
                        id: '/respondidas',
                        text: strings.respondidas,
                        onClick: () => {
                            if (history.location.pathname !== '/respondidas') {
                                history.push("/respondidas");
                            }
                        }
                    }
                ];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.VISUALIZACAO)) {
                menuOptions = [...menuOptions, {
                    id: '/historico',
                    text: strings.historico,
                    onClick: () => {
                        if (history.location.pathname === '/solicitacao') {
                            tabAtual.value = 0;

                            alerta.value = {
                                ...alerta.value,
                                open: true,
                                title: strings.atencao,
                                type: 'alert',
                                message: strings.retornarTelaPesquisa,
                                options: [
                                    {
                                        title: strings.ok,
                                        onClick: () => {
                                            if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
                                                listar.value = true;
                                            }
                                            history.push("/historico");
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    },
                                    {
                                        title: strings.cancelar,
                                        onClick: () => {
                                            tabAtual.value = 1;
                                            alerta.value = {
                                                ...alerta.value,
                                                open: false
                                            };
                                        }
                                    }
                                ],
                                onClose: () => {
                                    batch(() => {
                                        tabAtual.value = 1;
                                        alerta.value = {
                                            ...alerta.value,
                                            open: false
                                        };
                                    });
                                }
                            };
                            return;
                        }
                        if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
                            listar.value = true;
                        }
                        history.push("/historico");
                    }
                }];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.REGULACAO)) {
                menuOptions = [...menuOptions, {
                    id: '/regulacao',
                    text: strings.telerregulacao,
                    onClick: () => {
                        if (history.location.pathname !== '/regulacao') {
                            history.push("/regulacao");
                        }
                    }
                }];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.RESPOSTA)) {
                menuOptions = [
                    {
                        id: '/inicio',
                        text: strings.inicio,
                        onClick: () => {
                            if (history.location.pathname !== '/inicio') {
                                history.push("/inicio");
                            }
                        }
                    },
                    ...menuOptions,
                    {
                        id: '/resposta',
                        text: strings.aguardandoResposta,
                        onClick: () => {
                            if (history.location.pathname !== '/resposta') {
                                history.push("/resposta");
                            }
                        }
                    }
                ];
            }
            if (temPermissaoRBAC(usuario, PERMISSOES.ADMINISTRATIVO)) {
                menuOptions = [...menuOptions, {
                    id: '/administrativo',
                    text: strings.administrativo,
                    onClick: () => {
                        if (history.location.pathname !== '/administrativo') {
                            history.push("/administrativo");
                        }
                    }
                }];
            }
            submenus.value = menuOptions;
        }
    });

    useEffect(() => {
        submenus.value.forEach((menu, i) => {
            if (menu.id === history.location.pathname) {
                tabAtual.value = i;
            }
        });
    }, [submenus, history.location.pathname]);

    return (
        <div>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                modo={authModo.value}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
                submenus={submenus.value}
                selectedTab={tabAtual.value}
                callbackNavigationBar={(event, newTab) => { }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={usuario.value.perfisRBAC}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
            <SttHelpdeskShortcut
                config={global.gConfig}
                usuario={usuario.value}
            />
        </div>
    );
};

export default withRouter(Menu);